import { IMenuEntry } from 'global'
import { IDirectoryEntry } from 'global'
import { SIDEBAR } from '../messages'

const menuEntryReducer =
  (dbsMap: SMap<IDirectoryEntry>, favoritesView = false) =>
  (entries: IDirectoryEntry[], entry: IMenuEntry, index: number) => {
    const children =
      entry.children?.reduce(menuEntryReducer(dbsMap, favoritesView), []) || []

    const entriesLength = entries.length

    const lastAddedElement = entries[entriesLength - 1]

    const isPreviousElementSeparator =
      index > 0 &&
      entriesLength > 0 &&
      lastAddedElement &&
      lastAddedElement.type === 'separator'

    const isFirstElementSeparator = entriesLength === 0 && entry.type === 'separator'

    const childrenNotEmpty = children.length > 0
    const shouldBeIncluded = favoritesView ? childrenNotEmpty : true
    const onlySeparatorsInChildren =
      childrenNotEmpty && children.every(child => child.type === 'separator')

    if (
      favoritesView &&
      entries.length > 1 &&
      lastAddedElement.type === 'category' &&
      entry.type === 'category'
    ) {
      entries.pop()
    }

    switch (entry.type) {
      case 'db':
        const db = dbsMap[entry.name]
        if (db) {
          delete dbsMap[entry.name]
          entries.push(db)
        }
        return entries
      case 'folder':
        if (shouldBeIncluded && childrenNotEmpty && !onlySeparatorsInChildren) {
          entries.push({
            children,
            id: entry.name,
            description: entry.name,
            expanded: entry.expanded,
            collapsable: true,
            type: 'folder',
          })
        }
        return entries
      case 'separator':
        if (!favoritesView && !isFirstElementSeparator && !isPreviousElementSeparator) {
          entries.push({
            type: 'separator',
          })
        }
        return entries
      case 'category':
        entries.push({
          type: 'category',
          id: entry.name,
          description: entry.name,
        })
        return entries
      case 'archive':
        if (shouldBeIncluded && childrenNotEmpty && !onlySeparatorsInChildren) {
          entries.push({
            type: 'folder',
            id: entry.name,
            description: entry.name,
            expanded: false,
            collapsable: true,
            children,
          })
        }
        return entries
      default:
        return entries
    }
  }

export const convertMenuLayout = (
  dbs: IDirectoryEntry[],
  menu: IMenuLayout,
  favoritesView = false
): IDirectoryEntry[] => {
  const dbsMap = dbs.reduce((map: SMap<IDirectoryEntry>, db) => {
    map[db.id] = db
    return map
  }, {})

  const layout = menu.layout
    .reduce(menuEntryReducer(dbsMap, favoritesView), [])
    .reduce(emptyCategoriesReducer, [])

  const isLastElementSeparator = layout[layout.length - 1]?.type === 'separator'

  if (isLastElementSeparator) {
    layout.pop()
  }

  const remainingDbs = Object.values(dbsMap)
  if (remainingDbs.length > 0) {
    layout.push(
      {
        type: 'category',
        description: SIDEBAR.DATA_DIRECTORY_TAB.OTHER_DATABASES_SHORT,
      },
      {
        type: 'folder',
        description: SIDEBAR.DATA_DIRECTORY_TAB.OTHER_DATABASES,
        expanded: false,
        collapsable: true,
        children: remainingDbs,
      }
    )
  }

  return layout
}

function emptyCategoriesReducer(
  menuLayoutAcc: IMenuEntry[],
  menuLayoutEntry: IMenuEntry,
  index: number,
  originalMenuLayout: IMenuEntry[]
) {
  const isCategory = menuLayoutEntry.type === 'category'
  if (isCategory) {
    // if last element is category, do not add it to the menu layout
    const lastIndex = originalMenuLayout.length - 1
    if (index === lastIndex) {
      return menuLayoutAcc
    }

    if (originalMenuLayout.length > 1) {
      // if next element is category, do not add it to the menu layout
      const nextElement = originalMenuLayout[index + 1]
      if (nextElement.type === 'category') {
        return menuLayoutAcc
      }

      // if category has only separators inside, do not add it to the menu layout
      const isOnlySeparatorInside = isOnlySeparatorInsideCurrentCategory(
        originalMenuLayout,
        index
      )

      if (isOnlySeparatorInside) {
        return menuLayoutAcc
      }
    }

    // if menu layout has only category, do not add it to the menu layout
    if (originalMenuLayout.length === 1) {
      return menuLayoutAcc
    }
  }

  // remove duplicate separator in case empty category was removed previously
  if (
    menuLayoutEntry.type === 'separator' &&
    menuLayoutAcc[menuLayoutAcc.length - 1]?.type === 'separator'
  ) {
    return menuLayoutAcc
  }

  if (
    menuLayoutEntry.type === 'separator' &&
    originalMenuLayout[index + 1]?.type === 'category'
  ) {
    return menuLayoutAcc
  }

  return [...menuLayoutAcc, menuLayoutEntry]
}

function isOnlySeparatorInsideCurrentCategory(menuLayout: IMenuEntry[], index: number) {
  // look for index of next category and check if it is only separators inside of current category
  const restOfMenuLayout = menuLayout.slice(index + 1)
  const nextCategoryIndex = restOfMenuLayout.findIndex(
    element => element.type === 'category'
  )
  const indexOfNextCategoryOrEndOfArray =
    nextCategoryIndex === -1 ? restOfMenuLayout.length : nextCategoryIndex

  return restOfMenuLayout
    .slice(0, indexOfNextCategoryOrEndOfArray)
    .every(element => element.type === 'separator')
}
