import * as React from 'react'

import { BUTTONS, TABS } from '../../services/sidebar'
import { connect } from 'react-redux'
import { IRootState } from '../../store'
import { bindBem } from '../../bem'
import { SIDEBAR, EDIT_TITLES_DIALOG } from '../../messages'
import * as database from '../../store/Database'
import { ACTIONS as SERIES_ACTIONS } from '../../store/Series'
import SidebarButton from './SidebarButton'
import { AsideDialog } from '../Modal'
import { DropdownButton } from './DropdownButton'
import { ExportDialog } from './ExportDialog'
import GraphSettingsDialog from './GraphSettingsDialog'
import { downloadChartSVG, downloadChartPNG } from '../../services/export'

import './Menu.scss'

export interface IProps {
  isCollapsed?: boolean
}

export interface IStateProps {
  selectedTab: TABS
  databaseId: string
  variables: IDataSeries[]
  apiToken: string
  modal: SeriesModal
  title: string
}

export interface IActionProps {
  selectTab: (t: TABS) => void
  setActiveModal: (m: SeriesModal) => void
}

type Props = IStateProps & IActionProps & IProps

export class Menu extends React.PureComponent<Props> {
  render() {
    const { block, element } = bindBem('Menu')
    const { selectedTab, isCollapsed, modal, title } = this.props
    const { variables, apiToken } = this.props
    const dropdownMenuClass = variables.length === 0 ? 'disabled' : ''
    const wrapperRef = React.createRef<DropdownButton>()

    if (isCollapsed) {
      return null
    }

    return (
      <div className={`${block()} row middle-xs between-xs`}>
        <div className="col-xs-10 row start-xs">
          {BUTTONS.map(button => (
            <SidebarButton
              onClick={() => this.selectTab(button.tab)}
              key={button.name}
              label={button.name}
              selected={this.isNavigable(button.tab) && selectedTab === button.tab}
              disabled={!this.isNavigable(button.tab)}
              dataCy={button.dataCy}
            />
          ))}
        </div>

        <div className="col-xs-2 end-xs row">
          <DropdownButton stickTo="right" ref={wrapperRef}>
            <ul>
              <li
                className={dropdownMenuClass}
                onClick={() => this.openModal('editTitles')}
              >
                {EDIT_TITLES_DIALOG.TITLE}
              </li>
              <li className="separator" />
              <li className={dropdownMenuClass} onClick={() => this.openModal('options')}>
                {SIDEBAR.GRAPH_OPTIONS.TITLE}
              </li>
              <li className="separator" />
              <li className={dropdownMenuClass} onClick={() => this.openModal('export')}>
                {SIDEBAR.EXPORT_TAB.TITLE}
              </li>
              <li className="separator" />
              <li
                className={element('StartNewBtn')}
                onClick={() => {
                  window.open('/', '_blank')
                  wrapperRef.current.togglePortal()
                }}
              >
                {SIDEBAR.START_NEW}
              </li>
            </ul>
          </DropdownButton>
        </div>
        <AsideDialog
          isOpen={modal === 'export'}
          onClose={this.closeModal}
          title={SIDEBAR.EXPORT_TAB.TITLE}
        >
          <ExportDialog
            apiToken={apiToken}
            onDownloadSVG={() => downloadChartSVG(title)}
            onDownloadPNG={() => downloadChartPNG(title)}
            variables={variables}
            title={title}
          />
        </AsideDialog>
        <AsideDialog
          isOpen={modal === 'options'}
          onClose={this.closeModal}
          title={SIDEBAR.GRAPH_OPTIONS.TITLE}
        >
          <GraphSettingsDialog />
        </AsideDialog>
      </div>
    )
  }

  private selectTab = (selectedTab: number) =>
    this.isNavigable(selectedTab) && this.props.selectTab(selectedTab)

  private openModal = (modal: SeriesModal) => this.props.setActiveModal(modal)
  private closeModal = () => this.props.setActiveModal(null)

  private isNavigable = (tab: number) => {
    if (tab === TABS.SERIES) {
      return this.props.variables.length > 0
    }

    return true
  }
}

const mapStateToProps = (state: IRootState): IStateProps => {
  const { variables, activeModal, seriesSettings } = state.series
  const { route, sidebarTab } = state.databases
  const { user } = state.user
  return {
    selectedTab: sidebarTab,
    databaseId: route.length && route[0].id,
    variables,
    apiToken: user.settings.apiAccess ? user.apiToken : null,
    modal: activeModal,
    title: seriesSettings.title,
  }
}

const mapDispatchToProps = (dispatch: any): IActionProps => ({
  selectTab: (tab: TABS) => dispatch(database.ACTIONS.openTab(tab)),
  setActiveModal: m => dispatch(SERIES_ACTIONS.setActiveModal(m)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
