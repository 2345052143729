// eslint-disable max-lines
import classNames from 'classnames'
import scrollbarSize from 'dom-helpers/scrollbarSize'
import { times } from 'lodash'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { FixedSizeList as List, ListChildComponentProps } from 'react-window'
import { DataPointLookup, getDateAt } from 'services/table-date-cache'

import * as ga from '../../analytics'
import { bindBem } from '../../bem'
import { MAX_VARIABLES } from '../../constants'
import { FREQUENCY_LABEL, TOOLTIPS } from '../../messages'
import * as scatter from '../../services/hd3-utils/scatter-plot'
import { COLORS, formatLabel, getSeriesHeader, hasTrendLine } from '../../services/series'
import { estimateDistance, formatValue, getDatesExtent } from '../../services/table'
import {
  hasAggregation,
  hasInterpolation,
  isFunction,
  isFunctionApplied,
  isTransformationApplied,
} from '../../store/Transformations'
import { ReactComponent as Rect } from 'static/rect.svg'
import ToggleButton from '../ToggleButton'
import { AggregationButton, FxButton, InterpolationButton } from './Buttons'
import { SeriesDropdown } from './SeriesDropdown'

import './Table.scss'
import 'react-table/react-table.css'
import { CSSTransition, CSSTransitionProps } from 'react-transition-group'
import { collapsedTableHeight } from '../../containers/SeriesContainer/common'

export interface IProps {
  variables: IDataSeries[]
  frequency: Frequency
  stats: SMap<number>[]
  height: number
  width: number
  isHidden: boolean
  isScatterPlot?: boolean
  handleToggle: (isHidden: boolean) => void
  removeInterpolation?: (series: IDataSeries, index: number) => void
  removeTransformation?: (series: IDataSeries, index: number) => void
  transitionOptions?: CSSTransitionProps
}

interface IRenderProps {
  columnIndex: number
  rowIndex: number
  style: object
  variable?: IDataSeries
  startDate?: Date
  lookup?: DataPointLookup
}
const COLUMN_WIDTH = 140
const OVERSCAN_ROW_COUNT = 25
const ROW_HEIGHT = 30
const HEADER_HEIGHT = 57
const BOTTOM_BAR_HEIGHT = collapsedTableHeight + 1

type Point = { x: number; y: number }

const useScrollPosition = <T extends Element>(
  isEnabled: boolean,
  callback: (p: Point) => void
): [React.MutableRefObject<T>, React.MutableRefObject<Point>] => {
  const position = React.useRef({ x: 0, y: 0 })
  const elemRef = React.useRef<T>()
  React.useEffect(() => {
    if (!isEnabled) {
      position.current = { x: 0, y: 0 }
      return
    }
    const elem = elemRef.current
    if (!elem) {
      return
    }
    const onScroll = () => {
      const x = elem.scrollLeft
      const y = elem.scrollTop
      position.current = { x, y }
      callback(position.current)
    }
    elem.addEventListener('scroll', onScroll)

    return () => elem.removeEventListener('scroll', onScroll)
  }, [elemRef.current, isEnabled, callback])
  return [elemRef, position]
}

function getColumnWidth(width: number, columnCount: number) {
  const colWidth = (width - COLUMN_WIDTH - scrollbarSize()) / columnCount
  const columnsTooSmall = colWidth < COLUMN_WIDTH && columnCount > 1

  if (columnsTooSmall) {
    return COLUMN_WIDTH
  }
  return colWidth
}

export function FCTable(props: IProps) {
  const { isHidden, width, height, variables, frequency, stats, transitionOptions } =
    props
  const tableRef = React.useRef<HTMLDivElement>()
  const tableBody = React.useRef<HTMLDivElement>()
  const onHorizontalScroll = React.useCallback(
    ({ x }: Point) => {
      if (!tableRef.current) {
        return
      }
      const transform = `translate3d(${x}px, 0, 0)`
      tableRef.current.parentElement
        .querySelectorAll<HTMLDivElement>(
          '.Table__LeftSideHeader, .Table__LeftSideBodyCell'
        )
        .forEach(e => {
          e.style.transform = transform
        })
    },
    [tableRef.current]
  )
  const [scrollRef, offset] = useScrollPosition<HTMLDivElement>(true, onHorizontalScroll)
  const [selectedSeries, setSelectedSeries] = React.useState(0)
  if (!variables[selectedSeries]) {
    setSelectedSeries(variables.length - 1)
  }
  const selectedStats = stats[selectedSeries]
  const { block, element } = bindBem('Table')
  const [startDate, endDate] = React.useMemo(
    () => getDatesExtent(variables.filter(v => v.dataPoints.length)),
    [variables]
  )
  const rowCount = React.useMemo(
    () => Math.floor(estimateDistance(startDate, endDate, frequency)) + 1,
    [startDate?.valueOf(), endDate?.valueOf(), frequency]
  )
  const valueLookups = React.useMemo(
    () =>
      variables.map(
        s => new DataPointLookup(startDate, s.dataPoints, frequency, rowCount)
      ),
    [startDate?.valueOf(), variables, rowCount]
  )

  const tableBodyHeight = height - HEADER_HEIGHT - BOTTOM_BAR_HEIGHT

  const columnCount = variables.length
  const calculatedColumnWidth = getColumnWidth(width, columnCount)

  React.useLayoutEffect(() => {
    if (!isHidden) {
      scrollTable()
    }
  }, [variables, tableBody.current, isHidden])

  const scrollTable = () => {
    if (!tableBody.current) {
      return
    }
    tableBody.current.scrollTop = tableBody.current.scrollHeight
  }

  const toggle = () => {
    const toggledVisible = !isHidden
    props.handleToggle(toggledVisible)
    const action = toggledVisible ? 'hide' : 'show'
    ga.track('graph table', action)
  }

  const precision = variables[0].decimalPrecision

  return (
    <div className={block({ isOpen: !isHidden })} ref={tableRef}>
      <CSSTransition {...transitionOptions}>
        <div className={element('TableTransition')}>
          <ToggleButton isCollapsed={isHidden} onClick={toggle} vertical />
          <div className={element('GridRow')} ref={scrollRef}>
            <div className={element('GridColumn')}>
              <div
                className={element('HeaderRow')}
                style={{
                  height: HEADER_HEIGHT,
                }}
                data-cy="table-header"
              >
                <LeftSideHeader
                  frequency={frequency}
                  offset={offset.current.x}
                  height={HEADER_HEIGHT}
                />
                {variables.map((s, i) => (
                  <HeaderCell
                    columnIndex={i + 1}
                    rowIndex={0}
                    style={{ width: calculatedColumnWidth }}
                    removeInterpolation={props.removeInterpolation}
                    removeTransformation={props.removeTransformation}
                    variable={s}
                    canAddVariables={props.variables.length < MAX_VARIABLES}
                    isScatter={
                      props.isScatterPlot && scatter.isApplicable(props.variables)
                    }
                    key={s.uuid}
                  />
                ))}
              </div>
              <div
                className={element('GridContainer')}
                data-cy="table-body"
                style={{
                  height: tableBodyHeight,
                  position: 'relative',
                }}
              >
                <List
                  className={element('BodyGrid')}
                  height={tableBodyHeight}
                  width={width}
                  key={variables.length}
                  itemCount={rowCount}
                  itemSize={ROW_HEIGHT}
                  overscanCount={OVERSCAN_ROW_COUNT}
                  ref={rel => {
                    // eslint-disable-next-line react/no-find-dom-node
                    tableBody.current = ReactDOM.findDOMNode(rel) as HTMLDivElement
                  }}
                  itemData={{ valueLookups, variables, startDate, width, offset }}
                  itemKey={index => `${frequency}-${index}-${variables.length}`}
                >
                  {Row}
                </List>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <div className={element('BottomBar')}>
        <SeriesDropdown
          variables={variables}
          selectedSeries={selectedSeries}
          onChange={setSelectedSeries}
        />
        {selectedStats &&
          Object.keys(selectedStats).map((id, i) => (
            <span className={element('Stat')} key={`${id}-${i}`}>
              {`${id}: ${formatValue(selectedStats[id], precision)}`}
            </span>
          ))}
      </div>
    </div>
  )
}

const Row = React.memo(
  function BaseRow(props: ListChildComponentProps) {
    const { width, variables, offset, startDate, valueLookups } = props.data
    const columnCount = variables.length
    const calculatedColumnWidth = getColumnWidth(width, columnCount)
    const float_col_styles = {
      transform: `translate3d(${offset.current.x}px, 0, 0)`,
    }
    const { element } = bindBem('Table')
    const col_styles = {
      width: calculatedColumnWidth,
    }

    return (
      <div
        style={{ ...props.style, display: 'flex', width: '100%' }}
        className={element('TableRow', { isEven: props.index % 2 === 0 })}
      >
        <LeftSideCell
          columnIndex={0}
          rowIndex={props.index}
          style={float_col_styles}
          startDate={startDate}
          lookup={valueLookups[0]}
        />
        {times(columnCount, n => (
          <BodyCell
            columnIndex={n + 1}
            rowIndex={props.index}
            style={col_styles}
            variable={variables[n]}
            startDate={startDate}
            lookup={valueLookups[n]}
            key={variables[n].uuid}
          />
        ))}
      </div>
    )
  },
  (prev, next) => {
    const {
      variables: prevVars,
      width: prevWidth,
      offset: { current: prevOffset },
    } = prev.data
    const {
      variables: nextVars,
      width: nextWidth,
      offset: { current: nextOffset },
    } = next.data

    return (
      prev.index === next.index &&
      prev.style === next.style &&
      prevVars.length === nextVars.length &&
      prevVars.every((v: IDataSeries, i: number) => v.uuid === nextVars[i].uuid) &&
      prevOffset === nextOffset &&
      prevWidth === nextWidth
    )
  }
)

function BodyCell(props: IRenderProps) {
  const { element } = bindBem('Table')
  const variable = props.variable
  const lookup = props.lookup
  const value = props.lookup.at(props.rowIndex, variable.frequency)
  const isTransformation = !!variable.transformation || variable.isInterpolated
  const className = classNames(element('Cell'), element('BodyCell'), {
    empty: value === undefined,
    withBorder: value !== undefined && variable.frequency < lookup.frequency,
    isTransformation,
  })
  return (
    <div className={className} style={props.style} data-cy="table-row">
      <InnerCell {...props} value={value} />
    </div>
  )
}

function LeftSideCell(props: IRenderProps) {
  const { element } = bindBem('Table')
  const style = { ...props.style, width: COLUMN_WIDTH }
  return (
    <div
      className={`${element('Cell')} ${element('LeftSideBodyCell')}`}
      style={style}
      data-cy="left-side-cell"
    >
      {formatLabel(
        getDateAt(props.startDate, props.rowIndex, props.lookup.frequency),
        props.lookup.frequency
      )}
    </div>
  )
}

function InnerCell(props: IRenderProps & { value: number }) {
  const variable = props.variable
  const { value } = props
  const isNotDefined = value === undefined
  const formattedValue = isNotDefined
    ? null
    : formatValue(value, variable.decimalPrecision)
  return (
    <div className="InnerCell" data-cy="table-data-point">
      <span title={formattedValue ?? ''}>{formattedValue ?? ' '}</span>
    </div>
  )
}

type HeaderProps = IRenderProps & {
  removeInterpolation: (s: IDataSeries, index: number) => void
  removeTransformation: (s: IDataSeries, index: number) => void
  isScatter: boolean
  canAddVariables: boolean
  variable: IDataSeries
}

function HeaderCell(props: HeaderProps) {
  const { removeInterpolation, removeTransformation, variable } = props
  const index = props.columnIndex - 1
  const colorIndex = variable.colorIndex
  const isTrendLine = hasTrendLine(variable)
  const interpolationButtonVisible = !isTrendLine && hasInterpolation(variable)
  const aggregationButtonVisible = hasAggregation(variable)

  const canRevertTransformation =
    isTransformationApplied(variable) && props.canAddVariables
  const canRevertFunction = isFunctionApplied(variable)
  const displayFxButton = !isTrendLine && (canRevertFunction || canRevertTransformation)

  const { block, element } = bindBem('HeaderCell')
  const isMuted = index > 1 && props.isScatter
  const header = getSeriesHeader(variable)
  const tooltip = header.replace(' ', '')
  return (
    <div className={block({ isMuted })} style={props.style}>
      <div className={`${element('InnerCell')} column`}>
        <div
          className={`${element('RectWrapper')} row`}
          title={tooltip}
          data-cy="header-rect-wrapper"
        >
          <Rect
            className={element('Rect')}
            color={COLORS[colorIndex]}
            data-cy="svg-colour-icon"
          />
          <div className={element('HeaderId')} data-cy="header-id">
            {header}
          </div>
        </div>
        {(interpolationButtonVisible ||
          (displayFxButton && removeTransformation) ||
          aggregationButtonVisible) && (
          <div className={`${element('ButtonContainer')} row`}>
            {interpolationButtonVisible && (
              <InterpolationButton
                onClick={
                  removeInterpolation
                    ? () => removeInterpolation(variable, index)
                    : undefined
                }
              />
            )}
            {displayFxButton && removeTransformation && (
              <FxButton
                onClick={() => removeTransformation(variable, index)}
                title={TOOLTIPS.RESET}
                isArithmetic={!isFunction(variable.transformation)}
              />
            )}
            {aggregationButtonVisible && <AggregationButton />}
          </div>
        )}
      </div>
    </div>
  )
}

function LeftSideHeader(props: { offset: number; frequency: Frequency; height: number }) {
  const { element } = bindBem('Table')
  const style = {
    width: COLUMN_WIDTH,
    transform: `translate3d(${props.offset}px, 0, 0)`,
    height: props.height,
  }
  return (
    <div className={element('LeftSideHeader')} style={style} data-cy="left-side-header">
      {FREQUENCY_LABEL[props.frequency]}
    </div>
  )
}
