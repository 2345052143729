import { SIDEBAR } from '../messages'

export enum TABS {
  DIRECTORY,
  SERIES,
  DATA_UPDATES,
}

export const BUTTONS = [
  { name: SIDEBAR.DIRECTORY, tab: TABS.DIRECTORY, dataCy: 'sidebar-directory' },
  { name: SIDEBAR.SERIES, tab: TABS.SERIES, dataCy: 'sidebar-series' },
  { name: SIDEBAR.DATA_UPDATES, tab: TABS.DATA_UPDATES, dataCy: 'sidebar-data-updates' },
]
