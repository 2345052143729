import * as d3 from 'd3'
import { isEqual } from 'date-fns'
import * as React from 'react'
import * as FauxDOM from 'react-faux-dom'

import { XScale, renderAxisStyles, renderXAxis } from '../../services/hd3'

export interface IProps {
  scale: XScale
  height: number
  frequency: Frequency
  ticksType: TickmarksType
}

const DateXScale = (props: IProps) => {
  const svg = FauxDOM.createElement('g')
  const selection = d3.select(svg)
  const width = props.scale.range()[1]
  renderXAxis(
    selection,
    props.scale,
    width,
    props.height,
    props.frequency,
    props.ticksType
  )

  selection.selectAll('.tick').each(function (d: Date) {
    d3.select(this).attr('data-cy', `date-${d.getFullYear()}`)
  })

  renderAxisStyles(selection)

  return <>{React.Children.map(svg.toReact(), c => c.props.children)}</>
}

export default React.memo(DateXScale, (prevProps, props) => {
  const KEYS = Object.keys(props).filter(key => key !== 'scale') as keyof IProps[]
  if (!KEYS.every(k => prevProps[k] === props[k])) {
    return false
  }
  const [oldDomain, domain] = [prevProps.scale.domain(), props.scale.domain()]
  const [oldRange, range] = [prevProps.scale.range(), props.scale.range()]

  return (
    oldDomain.every((v, i) => isEqual(v, domain[i])) &&
    oldRange.every((v, i) => v === range[i])
  )
})
